@import url(http://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700);
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);
@import url(http://fonts.googleapis.com/css?family=Glegoo);
a {
  text-decoration: none;
}

h1 {
  font-family: Staatliches;
  font-size: 120px;
}
h3 {
  font-size: 17px;
  color: rgb(78, 78, 78);
}
h4 {
  font-family: Staatliches;
  font-size: 60px;
  color: #fff;
}
#category {
  font-family: Staatliches;
  font-weight: 500;
  color: black;
}

#title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  height: 400px;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background-attachment: fixed;
  background-size: cover;
  padding: 120px 0 25px 0;
  position: relative;
  background: #02bdd5 !important;
  color: #fff;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin:2%;
  border: 8px solid rgb(12, 186, 255);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(12, 186, 255) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cardBack{
  background: rgba(224, 223, 223, 0.5);
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.404);
}
.headerTextBl{
  margin-top: 1.5%;
  font-size: 40px;
}

hr.solid {
  margin-top: 10%;
  border-top: 3px solid #bbb;
  border-radius: 10px;
}

.spacingF{
  margin-top: 10%;
}

.cardHoldingMargin{
  margin-top:7.7%;
  margin-bottom: 5%;
}
.cardHoldingMargin2{
  margin-top:4%;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

